import '../dist/tailwind.css';
import '../node_modules/photoswipe/dist/photoswipe.css';
import Gumshoe from 'gumshoejs/src/js/gumshoe/gumshoe';
import Alpine from 'alpinejs'

window.Alpine = Alpine

Alpine.start()


document.addEventListener('DOMContentLoaded', () => {

  var spy = new Gumshoe('#ScrollMenu a');
  var spy = new Gumshoe('#MobileScroll a');

});
